#wrap {
  position: relative;
}
// Top
.name-logo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  @media (max-width: $width) {
    img {
      width: 250px;
      height: auto;
    }
  }
  @media (max-width: 756px) {
    img {
      width: 200px;
      height: auto;
    }
  }
}

.image-container {
  max-width: $width;
  height: auto;
  display: block;
  position: relative;
  margin: 0 auto;
  // overflow: hidden;
}

#main-svg {
  position: relative;
  display: block;
  width: 100%;
  height: auto;

  @media (max-width: 594px) {
    padding-top: 100px;
  }
  @media (max-width: 499px) {
    padding-top: 326px;
  }
}

// Introduction part

.introduction-text {
  width: 352px;
  height: 559px;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 52px;
  z-index: 99;
  padding: 10px 20px;
  @media (max-width: $width) {
    background-color: #dd0e93;
    border-radius: 30px;
    padding-top: 30px;
    top: 17px;
    right: 0px;
  }
  @media (max-width: 756px) {
    width: 75%;
    height: auto;
  }
  @media (max-width: 695px) {
    top: 55px;
    width: 95%;
    padding-bottom: 30px;
  }
  @media (max-width: 595px) {
    top: 55px;
    width: 100%;
  }
  @media (max-width: 385px) {
    z-index: 0;
  }
  h3 {
    font-weight: 400;
    font-size: 33.15px;
    color: white;
    line-height: 1.1;
    margin-bottom: 10px;
    @media (max-width: 775px) {
      font-size: 25.15px;
      margin-top: 15px;
    }
  }
  p {
    font-size: 14px;
    color: white;
    margin-bottom: 5px;
    line-height: 1.4;
    @media (max-width: 775px) {
      font-size: 12px;
    }
  }
  a {
    text-decoration: underline;
    color: white;
  }
}

.text-back {
  transform: translate(46%, 0px);
  @media (max-width: $width) {
    fill: transparent;
  }
}

#intro-background {
  transform: translate(0, 0);
  #pink-back {
    transform: translate(2px, 0px);
  }
}

.topLevelCloud {
  transform: translate(-4px, -1px);
}
.lastLevelCloud {
  transform: translate(-3px, 0px);
}
// SVG main content

svg {
  position: relative;
  overflow: visible;
  margin: 0;
  @media (max-width: $width) {
    // margin-top: 469px;
  }
}

#path {
  @media (max-width: $width) {
    display: none !important;
  }
}

#runner {
  @media (max-width: $width) {
    display: none !important;
  }
}

// Mobile path:
#mobile-path {
  @media (min-width: $width) {
    display: none;
  }
  @media (max-width: $width) {
    display: block;
  }
}

#kinderen {
  @media (max-width: $width) {
    scale: 1.2 !important;
  }
}

.section-title {
  fill: rgb(236, 0, 140);
  font-size: 20.6px;
  font-weight: normal;
}
.number {
  fill: purple;
  font-size: 30px;
  font-weight: bold;
}

.pulse-with-lines {
  cursor: pointer;
  pointer-events: auto !important;
  .pulse {
    filter: drop-shadow(0px 0px 10px rgb(236, 0, 140));
  }
}

.navigation {
  display: block;
  position: fixed;
  left: 30px;
  bottom: 30px;
  z-index: 99;
  @media (max-width: $width) {
    display: none !important;
  }
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    background-color: #522e91;
    padding: 10px 40px;
    border-radius: 20px;
    .active {
      span {
        background-image: linear-gradient(#2f165b, #7353ab);
        padding: 18px;
        position: relative;
        border-radius: 30px;
      }
    }
    li {
      cursor: pointer;
      color: white;
      padding: 0 10px;
      line-height: 1;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.extra-footer {
  position: absolute;
  bottom: 29px;
  right: 15px;
}

.broek {
  position: absolute;
  bottom: 122px;
  right: 302px;
  z-index: 9;
}

.green-footer {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  width: 100%;
  height: $height;
  z-index: -2;
  @media (max-width: $width) {
    display: none !important;
  }
  .green-grass {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #7bc143;
    width: 100%;
    height: 127px;
  }
}

#intro-background {
  @media (max-width: 756px) {
    display: none !important;
  }
}

#XMLID_00000089542071515361638180000012227052148281000343_ {
  @media (max-width: 385px) {
    display: none !important;
  }
}
