.app {
  &__topbar {
    background-color: white;

    .logo {
      width: 12.8676vw;
      margin-left: 40px;

      @include breakpoint-down("phone") {
        width: 20vw;
      }
    }
  }

  &__hero {
    @include breakpoint-up("desktop") {
    }

    @include breakpoint-down("phone") {
    }
  }

  &__content {
  }

  &__footer {
  }
}
